import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './CustomCarousel.css';

function CustomCarousel({ banners, activeImage, onSlideChange, baseUrl }) {
  return (
    <div className="custom-carousel-container">
      <Carousel
        showStatus={false}
        showThumbs={false}
        onChange={onSlideChange}
        autoPlay={true}
        interval={3000}
        infiniteLoop={true}
      >
        {banners.map((banner, index) => (
          <div key={index}>
            <img src={banner} alt={`Imagem ${index + 1}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default CustomCarousel;
