import React from 'react';
import './SectionUni.css';

// Importe a imagem
import uni from '../assets/images/unilogo_plus.png'; // Atualize o caminho conforme a estrutura do seu projeto

function SectionUni() {
    return (
        <div className="section-uni-body" id="uni">
            <div className="section-uni-boxes">
                <div className="column-wrapper">
                    <div className="left-uni-column"><br></br><br></br><br></br><br></br><br/>
                        <img src={uni} alt="Imagem com Logo da UNI e as empresas participantes do grupo" width="300" />
                    </div>
                    <div className="right-uni-column">

                        <h2 className="section-title">Desfrute de uma navegação livre de
                            preocupações</h2>
                        <p className="section-subtitle">A Uni Internet oferece os melhores planos para garantir que você tenha acesso
                            a uma conexão de alta velocidade, seja para trabalho, estudos, entretenimento
                            ou qualquer outra atividade online<br></br><br></br>Com a nossa conexão, você pode trabalhar remotamente sem preocupações
                            com interrupções na conexão, fazer videochamadas em alta definição e sem
                            atrasos, assistir a filmes e séries em streaming sem interrupções, e muito mais.
                            Além disso, com uma boa conexão de internet, você pode acessar serviços e
                            informações importantes de qualquer lugar do mundo, sem precisar sair de
                            casa. Então, se você quer melhorar sua qualidade de vida, escolha agora
                            mesmo e comece a navegar com tranquilidade e velocidade.</p><br></br><br></br><br></br>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionUni;
